import ahoy from 'ahoy.js';

window.addEventListener('load', function() {
    // LOG AHOY EVENTS
    let signup_selectors = ['#mtrSignup', '#mtrSignup1', '#mtrSignup2', '#mtrLogin'];
    let stripe_connect_selectors = ['#mtrStripeConnect', '#connectStripeBtn'];
    let ahoy_click_selector = signup_selectors.concat(stripe_connect_selectors);
    let ahoy_selectors = document.querySelectorAll(ahoy_click_selector.map(id => `${id}`).join(', '));

    ahoy_selectors.forEach(ahoyClickEvent);

    function ahoyClickEvent(item) {
        item.addEventListener('click', function() {
            ahoy.track(item.id, item);
        });
    }

    // USERS MONITORING LOGS
    var selectors = signup_selectors.concat(stripe_connect_selectors);
    var elements = document.querySelectorAll(selectors.map(id => `${id}`).join(', '));
    elements.forEach(logClickEvent);

    // LOG IF USER CLICKS ON AN ELEMENT
    function logClickEvent(item) {
        item.addEventListener('click', function() {
            let xhttp = new XMLHttpRequest();
            xhttp.open('POST', '/log-event?event_type=button_click&event_name=' + item.id, true);
            xhttp.send();
        });
    }

    // LOG IF AN ELEMENTS EXISTS IN UI
    var ex_selectors = stripe_connect_selectors;
    var ex_elements = document.querySelectorAll(ex_selectors.map(id => `${id}`).join(', '));
    ex_elements.forEach(logElementExestenceEvent);

    function logElementExestenceEvent(item) {
        if (item.id != null) {
            let xhttp = new XMLHttpRequest();
            xhttp.open('POST', '/log-event?event_type=element_existence&event_name=' + item.id, true);
            xhttp.send();
        }
    }

    let field_selectors = ['#mtrPayMeBusinessName', '#mtrPayMeBusinessEmail'];
    let field_elements = document.querySelectorAll(field_selectors.map(id => `${id}`).join(', '));
    field_elements.forEach(logFieldTextEvent);

    // LOG IF USER ENTERS AN INTO AN INPUT FIELD
    function logFieldTextEvent(item) {
        item.addEventListener('input', function() {
            let xhttp = new XMLHttpRequest();
            let monitoring_params = 'event_name=' + item.id + '&pathname=' + window.location.pathname;
            xhttp.open('POST', '/log-event?event_type=input_field_filled&' + monitoring_params, true);
            xhttp.send();
        });
    }
});
