import '@babel/polyfill';

import jQuery from 'jquery';
window.$ = jQuery;

import 'bootstrap/js/dist/util';
//# import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
//# import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
//# import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
//# import 'bootstrap/js/dist/popover';
//# import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
//# import 'bootstrap/js/dist/tooltip';

import 'asset_javascript/application/users_monitoring.js';

document.addEventListener('DOMContentLoaded', () => {
  $('.flash').toast({ delay: 3000 }).toast('show');
});
